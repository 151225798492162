import React from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import ContentText from '@ui-components/ContentText'
import Reveal from '@ui-components/Reveal'
import Divider from '@ui-components/Divider'
import ContentImages from '@ui-components/ContentImages'
import QuickSignupForm from '@ui-components/QuickSignupForm'
import ContentButtons from '@ui-components/ContentButtons'
import WithDefaultContent from '@ui-blocks/WithDefaultContent'
import ContentForm from '@ui-components/ContentForm/ContentForm'

const HeroBlock10 = ({
  content: { text = [], images, buttons, form },
  reverse,
  locles
}) => {
  return (
    <Container>
      <Flex
        sx={{
          alignItems: [null, null, null, `center`],
          flexDirection: [
            reverse ? `column-reverse` : `column`,
            null,
            null,
            reverse ? `row-reverse` : `row`
          ]
        }}
      >
        <Box
          sx={{
            flexBasis: [null, null, null, `50%`],
            [reverse ? 'ml' : 'mr']: [null, null, null, 5],
            position: `relative`,
            textAlign: `center`
          }}
        >
          {/* <ContentImages
          content={{ images }}
          loading='eager'
          reverse={reverse}
          imagePosition='center'
        /> */}
          {form && (
            <ContentForm
              form={form}
              locles={locles}
            // id={`form.${identifier}.${form.multiStep ? 'multi' : index
            //   }`}
            />
          )}
        </Box>
        <Box
          sx={{
            flexBasis: `50%`,
            textAlign: [`center`, null, null, `left`]
          }}
        >
          <Reveal effect='fadeInDown'>
            <ContentText content={text} />
          </Reveal>
          {buttons && (
            <Reveal
              effect='fadeInRight'
              delay={1}
              css={css({ mb: [4, null, null, 0] })}
            >
              {buttons && (
                <div style={{ minWidth: 'max-content' }}>
                  <Divider space={3} />
                  <ContentButtons content={buttons} />
                </div>
              )}
            </Reveal>
          )}
          {/* {form && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ mb: [4, null, null, 0] })}
          >
            <QuickSignupForm {...form} space={3} />
          </Reveal>
        )} */}
        </Box>
      </Flex>
    </Container>
  )
}

export default WithDefaultContent(HeroBlock10)
